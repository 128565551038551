@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

@tailwind base;

@tailwind components;

@layer components {
  .modal-open {
    overflow: hidden;
  }
  .px-main {
    @apply px-36;
  }
  .px-navigation {
    @apply px-24;
  }
  .w-product {
    width: 100%;
  }
  .-mx-navigation {
    @apply -mx-20;
  }
  .max-w-navigation {
    max-width: 68rem;
    margin-left: auto;
    margin-right: auto;
    @apply px-4 md:px-8 xl:px-0;
  }
  .max-w-main {
    @apply px-6 md:px-8 xl:px-0 max-w-5xl mx-auto;
  }
  .maplexxon-animation-translate-start {
    @apply translate-y-10 opacity-0;
  }
  .maplexxon-animation-scale-start {
    @apply scale-90 opacity-0;
  }
  .maplexxon-animation-end {
    @apply translate-y-0 scale-100 opacity-100 transition-all duration-500;
  }
  .w-item-1 {
    width: 100%;
  }
  .w-item-2 {
    width: calc(50% - theme("gap.8"));
  }
  .w-item-3 {
    width: calc(100% / 3 - theme("gap.8"));
  }
  .w-item-4 {
    width: calc(100% / 4 - theme("gap.8"));
  }
  .w-use-case-item {
    width: calc(100% / 2 - theme("gap.4"));
  }
  .companies-img {
    position: relative;
    animation: mymove 25s linear infinite;
  }

  .switch {
    position: relative;
    display: inline-block;
    height: calc(16px + 2px * 2);
    width: calc(16px * 2 + 2px * 2);
    min-width: calc(16px * 2 + 2px * 2);
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .switch input:checked + .slider {
    background-color: #418dff;
  }
  .switch input:focus + .slider {
    box-shadow: 0 0 1px black;
  }
  .switch input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cccccc;
    -webkit-transition: 0.15s;
    transition: 0.15s;

    border-radius: calc(16px + 2px * 2);
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  /* width */
  ::-webkit-scrollbar {
    @apply w-2 sm:w-3;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-maplexxon-grey-light-2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-maplexxon-grey-4 border-2 border-solid border-maplexxon-grey-light-2;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-maplexxon-grey-4/80;
  }

  .chat-widget .maplexxon-support-chat-chat-container {
    @apply w-72 md:w-80 bg-white drop-shadow-sm rounded-2xl;
  }
  .chat-widget .maplexxon-support-chat-chat-inner-container {
    @apply h-[500px] md:h-[530px] flex flex-col;
  }
  .chat-widget .maplexxon-support-chat-chat-message-container {
    @apply relative flex-1 px-4 py-3 overflow-y-auto;
  }
  .chat-widget .maplexxon-support-chat-chat-bot-message-container {
    @apply flex mb-3;
  }
  .chat-widget .maplexxon-support-chat-user-chat-message-container {
    @apply flex mb-3 justify-end;
  }
  .chat-widget .maplexxon-support-chat-chat-input-form {
    @apply text-xs pb-2 flex;
  }
  .chat-widget .maplexxon-support-chat-chat-input {
    @apply text-xs py-2 px-4 flex-1 focus:outline-none;
  }
  .chat-widget .maplexxon-support-chat-chat-btn-send {
    @apply bg-white py-2 px-4 !important;
  }
  .chat-widget .maplexxon-support-chat-chat-btn-send-icon {
    @apply fill-black h-3 w-3;
  }

  input[type="checkbox"] {
    @apply w-12;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @keyframes mymove {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @media only screen and (min-width: 640px) {
  }
  @media only screen and (min-width: 768px) {
    .w-product {
      width: calc(50% - theme("gap.8"));
    }
    .w-use-case-item {
      width: calc(100% / 3 - theme("gap.8"));
    }
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1280px) {
  }
  @media only screen and (min-width: 1536px) {
  }
}
@tailwind utilities;
